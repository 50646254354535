<template>
  <div :hidden="!visible">
    <div class="row mb-3">
      <div class="col">
        <b-button-group>
          <b-button variant="outline-primary" v-on:click="loadData"><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>
        </b-button-group>
      </div>
      <div class="col-lg-4 col-md-12">
        <b-form v-on:submit.prevent="loadData">
          <b-input-group>
            <b-input type="text" ref="search" placeholder="Search..." v-model="search"/>
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="outline-primary" v-on:click="loadData"><b-icon-search></b-icon-search></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table id="main-table" striped hover :items="items" :fields="fields" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(articlenumber)="row">
            <span class="link text-primary" v-on:click="onEdit(row)">{{ row.item.articlenumber }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span>
        Showing {{ ((currentPage - 1) * currentPageSize) + 1 }} to {{ activeRows() }} of {{ totalItems }} rows 
        <b-dropdown variant="outline-primary" :text="(currentPageSize).toString()" v-model="currentPageSize">
          <b-dropdown-item v-on:click="currentPageSize=10">10</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=20">20</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=50">50</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=100">100</b-dropdown-item>
        </b-dropdown>
        rows per page</span>
      </div>
      <div class="col">
        <b-pagination align="right" v-if="!isBusy" v-model="currentPage" :per-page="currentPageSize" :total-rows="totalItems" aria-controls="main-table"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { productsService } from '@/services';
import { tablePagination } from '@/mixins/table-pagination'

export default {
  name: 'ProductsTable',
  props: [
    'visible'
  ],
  mixins: [
    tablePagination
  ],
  data () {
    return {
      fields: ['articlenumber'],
      isBusy: false, // Data Loading State
      search: '' // Search field
    }
  },
  watch: {
    currentPage() {
      this.loadData()
    },
    currentPageSize() {
      this.loadData()
    }
  },
  methods: {
    async onEdit(row) {
      this.$emit('show', row.item)
    },
    loadData() {
      this.isBusy = true
      productsService.getAll(this.search, this.currentPageSize, this.currentPage)
        .then(data => {
          this.totalItems = data.count
          this.items = data.data
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
        .then(() => {
          this.isBusy = false
          this.$refs.search.$el.focus()
        })
    }
  },
  async mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
body {
  text-align: left !important;
}
.link {
  cursor: pointer;
}
</style>