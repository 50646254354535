<template>
  <div class="settings_manufacturing_boxes">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='products'" v-on:click="tab='products'">Products</b-nav-item>
      <b-nav-item v-if="tab=='current'" :active="tab=='current'">{{ loadedProduct.articlenumber }}</b-nav-item>
    </b-nav>

    <products :visible="tab=='products'" @show="onShowSerials"></products>
    <serials v-if="tab=='current'" :product="loadedProduct"></serials>

  </div>
</template>

<script>
import products from '@/components/manufacturing.products/products'
import serials from '@/components/manufacturing.products/serials'

export default {
  name: 'Products',
  components: {
    products,
    serials
  },
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Manufacturing',
          to: '/manufacturing'
        },
        {
          text: 'Products',
          active: true
        }
      ],
      loadedProduct: null
    }
  },
  watch: {
    tab() {
      if(this.tab != 'current') {
        this.loadedProduct = null
      }
    }
  },
  methods: {
    onShowSerials(data) {
      this.tab = 'current'
      this.loadedProduct = data
    },
  },
  async mounted() {
    this.tab = 'products'
  }
}
</script>
